import * as React from "react"

import FooterLayout from "../components/footer"
import Menu from "../components/home"

export default function Contact() {
    return (
        <Menu>
            <FooterLayout>
                <h1>Contact us</h1>
                <p>For support, troubleshooting, feature requests, more information about usage of your data please send an email to <a href='mailto:geognosisapp@gmail.com'>geognosisapp@gmail.com</a></p>
            </FooterLayout>
        </Menu>
    );
  }